
import "@hotwired/turbo-rails"
import '@tabler/core/src/js/tabler.js'

Turbo.session.drive = false

import "./controllers"

import ApexCharts from 'apexcharts'

// globals
window.ApexCharts = ApexCharts

// console.log('Hello from application.js')
