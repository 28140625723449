
/**
  * Fetches the status of the dashboard
  *
  * @param {Object} params - The query parameters 
  * @returns {Promise<Object>} status data
 */
export const fetchStatus = async (params = new URLSearchParams()) => {
  if (!params.has('period') || params.get('period') == null) params.set('period', 'today')

  return fetch(`/dashboard.json?${params.toString()}`, {cache: "reload", headers: {accept: 'application/json'}})
    .then(response => response.json())
}
