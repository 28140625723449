import {Controller} from "@hotwired/stimulus"

import ApexCharts from "apexcharts"

import {currentLocation, fetchStatus} from "../resources/index.js"

const CHART_OPTIONS = {
  pie: {
    chart: {
      type: "pie",
      height: 300,
      fontFamily: 'inherit',
      sparkline: { enabled: true },
      animations: { enabled: false },
    },
    fill: { opacity: 1, },
    tooltip: { fillSeriesColor: false },
    legend: {
      fontSize: '10px',
      position: "bottom",
      height: 75,
      show: true,
      offsetY: 12,
      markers: { width: 10, height: 10, radius: 100, },
      itemMargin: { horizontal: 8, vertical: 8 },
    }
  },
  bar: {
    chart: {
      type: "bar",
      fontFamily: 'inherit',
      height: 300,
      parentHeightOffset: 0,
      toolbar: { show: false },
      animations: { enabled: false },
    },
    plotOptions: {
      bar: { barHeight: '50%', horizontal: true }
    },
    dataLabels: { enabled: false, },
    fill: { opacity: 1, },
    tooltip: { theme: 'dark' },
    grid: {
      padding: { top: -20, right: 0, left: -4, bottom: -4 },
      strokeDashArray: 4,
    },
    xaxis: {
      labels: { padding: 0, },
      tooltip: { enabled: false },
      axisBorder: { show: false },
      type: 'category',
    },
    yaxis: {
      labels: { padding: 4 },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Total'],
    },
  }
}



// Connects to data-controller="dashboards"
export default class extends Controller {
  static targets = ["period"]

  async connect() {
    await this.#loadStatus()
  }

  async setPeriod(event) {
    const url = currentLocation()

    url.searchParams.set('period', event.target.value)
    window.history.pushState({}, '', url)
    
    for (const option of event.target.options) {
      const isSelected = option.value == event.target.value
      option.removeAttribute('selected')

      if (isSelected)
        option.setAttribute('selected', "")
    }

    await this.#loadStatus()
  }

  async #loadStatus() {
    const url = currentLocation()

    await fetchStatus(url.searchParams).then(data => this.#renderCharts(data))
  }

  async #renderCharts(data) {
    this.charts = data.charts

    for (const [key, chart] of Object.entries(this.charts)) {
      const selector = `${key}-chart`

      const chartType = ['projects', 'messages'].includes(key) ? 'bar' : 'pie'
      const values = this.#toChartData(chart, chartType)

      const options = {
        ...CHART_OPTIONS[chartType],
        ...(chartType === 'pie' ? values : { series: [values] }),
        chart: { id: selector, ...CHART_OPTIONS[chartType].chart },
      }

      if (this.charts[selector] != null) {
        await this.charts[selector].updateSeries([{ data: chart }])
        continue
      }

      const element = document.querySelector(`#${selector}`)
      if (!element) continue

      this.charts[selector] = new ApexCharts(element, options)
      this.charts[selector].render()
    }
  }

  /**
    * Converts the data to the format expected by ApexCharts
    * @private
    *
    * @param {Object} data (label: serie format)
    * @returns {Object} chart data (labels and series)
    */
  #toChartData(data, type = 'pie') {
    if (type === 'pie')
      return Object.entries(data).reduce((previous, current) => {
        const [label, serie] = current
        return {
          labels: [...previous.labels, label],
          series: [...previous.series, serie]
        }
      }, {labels: [], series: []})

    return Object.entries(data).sort((a, b) => b[1] - a[1]).reduce((previous, current) => {
      const [label, serie] = current
      return {
        name: 'Total',
        data: [
          ...previous.data,
          { x: label, y: serie }
        ]
      }
    }, {data: []})
  }
}
